<template lang="html">
    <div class="container-fluid p-5">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        Print View
                    </div>
                    <div class="card-body">
                        <div class="row" id="myelement">
                            <div class="col-md-12" style="padding-right: 0px; padding-left: 0px;">
                                <div class="col-md-12" style="height: 50px;">
                                    <p style="margin-top: 7px;margin-bottom: 0px;text-align:center;font-size: 19px;font-weight: bold;">လက်မှတ်ပျောက် ဘောင်ချာ (ရွှေ)</p>
                                    <p style="margin-top: 7px;margin-bottom: 0px;margin-left: 34%;font-size: 19px;font-weight: bold;">ငြိမ်း လိုင်စင်ရ အပေါင်ဆိုင်
                                    <span v-if="mortgageRequest.shopId == 1">(တောင်ဒဂုံ)</span>
                                    <span v-if="mortgageRequest.shopId == 2">(ရွှေပြည်သာ)</span>
                                    <span v-if="mortgageRequest.shopId == 3">(လှိုင်သာယာ)</span>
                                    <span style="margin-left: 1%;"></span></p>
                                    
                                    <p v-if="mortgageRequest.shopId == 1" style="margin-left: 31%;font-size: 12px;margin-top: 0px;">အမှတ် - ၁၂၀၅ (က/ခ) ၊ လှော်ကားလမ်း ၊ (၇၀)ရပ်ကွက် ၊ တောင်ဒဂုံမြို့နယ်။</p>
                                    <p v-if="mortgageRequest.shopId == 2" style="margin-left: 31%;font-size: 12px;margin-top: 0px;">အမှတ် - ၄၂၁ ၊ ဘုရင့်နောင်လမ်း ၊ ၅/၈ရပ်ကွက် ၊ ရွှေပြည်သာမြို့နယ်</p>
                                    <p v-if="mortgageRequest.shopId == 3" style="margin-left: 31%;font-size: 12px;margin-top: 0px;">အမှတ် - ၆၅၆ ၊ ဗိုလ်အောင်ကျော်လမ်း ၊ (၁၄)ရပ်ကွက် ၊ လှိုင်သာယာမြို့နယ်။</p>

                                </div>
                                <div class="col-md-12" style="height: 240px; margin-top: 70px;margin-left: 4%;">
                                    <div style="width: 100%; float: left;">
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">Code No </div>
                                        <div style="width: 78%;float:left;margin-top: 4px;">
                                            {{mortgageRequest.noDate}}-<span v-if="mortgageRequest.noType != 'No'">{{mortgageRequest.noType}}-</span>{{mortgageRequest.no}}
                                        </div>
                                    </div>
                                    <div style="width: 100%; float: left;">
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">ပစ္စည်း အမျိုးအမည် </div>
                                        <div style="width: 78%;float:left;margin-top: 4px;">
                                            {{mortgageRequest.type}}
                                        </div>
                                    </div>
                                    <div  style="width: 100%;float: left;">
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">ကျောက်ပါ အလေးချိန် </div>
                                        <p style="padding-left: 2%;padding-top: 4px;font-size: 16px;width: 100%;margin: 0px;">
                                        <span v-if="mortgageRequest.mortgageWeight.v1 != null">{{mortgageRequest.mortgageWeight.v1}} ကျပ်</span>
                                        <span v-if="mortgageRequest.mortgageWeight.v2 != null">{{mortgageRequest.mortgageWeight.v2}} ပဲ</span>
                                        <span v-if="mortgageRequest.mortgageWeight.v3 != null">{{mortgageRequest.mortgageWeight.v3}} ရွေး</span>
                                    </p>
                                    </div>
                                    <div  style="width: 100%;float: left;">
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">ချေးငွေ KS </div>
                                        <div style="width: 20%;float:left;font-size: 16px;margin-top: 7px;">
                                            {{mortgageRequest.loan}}
                                        </div>
                                        <div style="width: 40%;float:left;font-size: 16px;margin-top: 4px;">
                                            ကျပ် - {{mortgageRequest.loanMmk}}
                                        </div>
                                    </div>
                                     <div  style="width: 100%;float: left;">
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">( စာဖြင့် ) </div>
                                        <div style="width: 78%;float:left;margin-top: 4px;">
                                            {{mortgageRequest.loanText}} ကျပ်
                                        </div>
                                    </div>
                                    <div  style="width: 100%;float: left;">
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">ပေါင်နှံသူ အမည် </div>
                                        <div style="width: 30%;float:left;margin-top: 4px;">
                                            {{mortgageRequest.name}}
                                        </div>
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">မှတ်ပုံတင် အမှတ် - </div>
                                        <div v-if="mortgageRequest.nrc" style="width:10%;float:left;margin-top: 4px;">
                                            {{mortgageRequest.nrc}}
                                        </div>
                                         <!-- <div v-else style="width:40%;float:left;margin-top: 23px;">
                                            {{mortgageRequest.nrc}}
                                        </div> -->
                                    </div>
                                    <div  style="width: 100%;float: left;">
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">နေရပ်လိပ်စာ </div>
                                         <div style="width: 30%;float:left;margin-top: 4px;">
                                            {{mortgageRequest.address}}
                                        </div>
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">ဒဏ်ကြေး - </div>
                                        <div style="width:10%;float:left;margin-top: 4px;">
                                            
                                        </div>
                                    </div>
                                    <div style="width:100%;float: left;">
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">ပေါင်နှံသည့်နေ့စွဲ </div>
                                        <div style="width: 60%;float: left;">
                                            <p style="padding-left: 4px;;padding-top: 10px;font-size: 16px;width: 100%;margin: 0px;">
                                                {{mortgageRequest.day}}-{{mortgageRequest.month}}-{{mortgageRequest.year}}
                                            </p>
                                        </div>
                                    </div>
                                    <div style="width:100%;float: left;">
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">ရွှေးရမည်နေ့စွဲ </div>
                                        <div style="width: 60%;float: left;">
                                            <p style="padding-left: 4px;;padding-top: 10px;font-size: 16px;width: 100%;margin: 0px;">
                                                {{mortgageRequest.expDate}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style="width: 100%; height: 70px;">
                                <p style="text-align: center;">ဤလက်မှတ်ဖြင့် ပစ္စည်းရွေးယူခွင့်မရှိ။ (ရက်ချိန်းပြည့်မှလာရန်။)</p>
                                <p style="text-align: center;">မူရင်းဘောင်ချာတွေ့လျှင် ဤလက်မှတ်နှင့်အတူ လာရောက်အကြောင်းကြားရန်။</p>
                            </div>

                            <div style="width: 100%;margin-top: 180px;height: 500px;padding-right: 0px; padding-left: 0px;">
                                <div class="col-md-12" style="height: 240px; margin-top: 70px;;margin-left: 4%;">
                                    <div style="width: 100%; float: left;">
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">Code No </div>
                                        <div style="width: 78%;float:left;margin-top: 4px;">
                                            {{mortgageRequest.noDate}}-<span v-if="mortgageRequest.noType != 'No'">{{mortgageRequest.noType}}-</span>{{mortgageRequest.no}}
                                        </div>
                                    </div>
                                    <div style="width: 100%; float: left;">
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">ပစ္စည်း အမျိုးအမည် </div>
                                        <div style="width: 78%;float:left;margin-top: 4px;">
                                            {{mortgageRequest.type}}
                                        </div>
                                    </div>
                                    <div  style="width: 100%;float: left;">
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">ကျောက်ပါ အလေးချိန် </div>
                                        <p style="padding-left: 25%;padding-top: 4px;font-size: 16px;width: 100%;margin: 0px;">
                                        <span v-if="mortgageRequest.mortgageWeight.v1 != null">{{mortgageRequest.mortgageWeight.v1}} ကျပ်</span>
                                        <span v-if="mortgageRequest.mortgageWeight.v2 != null">{{mortgageRequest.mortgageWeight.v2}} ပဲ</span>
                                        <span v-if="mortgageRequest.mortgageWeight.v3 != null">{{mortgageRequest.mortgageWeight.v3}} ရွေး</span>
                                    </p>
                                    </div>
                                    <div  style="width: 100%;float: left;">
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">ချေးငွေ KS </div>
                                        <div style="width: 20%;float:left;font-size: 16px;margin-top: 7px;">
                                            {{mortgageRequest.loan}}
                                        </div>
                                        <div style="width: 40%;float:left;font-size: 16px;margin-top: 4px;">
                                            ကျပ် - {{mortgageRequest.loanMmk}}
                                        </div>
                                    </div>
                                     <div  style="width: 100%;float: left;">
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">( စာဖြင့် ) </div>
                                        <div style="width: 78%;float:left;margin-top: 4px;">
                                            {{mortgageRequest.loanText}} ကျပ်
                                        </div>
                                    </div>
                                    <div  style="width: 100%;float: left;">
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">ပေါင်နှံသူ အမည် </div>
                                        <div style="width: 30%;float:left;margin-top: 4px;">
                                            {{mortgageRequest.name}}
                                        </div>
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">မှတ်ပုံတင် အမှတ် - </div>
                                        <div v-if="mortgageRequest.nrc" style="width:10%;float:left;margin-top: 4px;">
                                            {{mortgageRequest.nrc}}
                                        </div>
                                         <!-- <div v-else style="width:40%;float:left;margin-top: 23px;">
                                            {{mortgageRequest.nrc}}
                                        </div> -->
                                    </div>
                                    <div  style="width: 100%;float: left;">
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">နေရပ်လိပ်စာ </div>
                                         <div style="width: 30%;float:left;margin-top: 4px;">
                                            {{mortgageRequest.address}}
                                        </div>
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">ဒဏ်ကြေး - </div>
                                        <div style="width:10%;float:left;margin-top: 4px;">
                                            
                                        </div>
                                    </div>
                                    <div style="width:100%;float: left;">
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">ပေါင်နှံသည့်နေ့စွဲ </div>
                                        <div style="width: 60%;float: left;">
                                            <p style="padding-left: 4px;;padding-top: 10px;font-size: 16px;width: 100%;margin: 0px;">
                                                {{mortgageRequest.day}}-{{mortgageRequest.month}}-{{mortgageRequest.year}}
                                            </p>
                                        </div>
                                    </div>
                                    <div style="width:100%;float: left;">
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">ရွှေးရမည်နေ့စွဲ </div>
                                        <div style="width: 60%;float: left;">
                                            <p style="padding-left: 4px;;padding-top: 10px;font-size: 16px;width: 100%;margin: 0px;">
                                                {{mortgageRequest.expDate}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style="float:left;width:200px;height:100px;margin-top: 30px;;margin-left: 4%;">
                                <p style="margin:0px;padding-left:20px;font-size: 18px;">
                                    လက်မှတ်ပျောက်
                                </p>
                                <p style="margin:0px;padding-left:20px;font-size: 18px;">
                                    {{mortgageRequest.noDate}}-<span v-if="mortgageRequest.noType != 'No'">{{mortgageRequest.noType}}-</span>{{mortgageRequest.no}}
                                </p>
                                <p style="margin:0px;padding-left:20px;font-size: 18px;" v-if="mortgageRequest.referenceNote">
                                    {{mortgageRequest.referenceNote}}
                                </p>
                                <p style="margin:0px; padding-left:20px;font-size: 18px;">
                                    {{mortgageRequest.type}}
                                </p>
                            </div>
                            
                            <div style="float:left;width:200px;height:100px;margin-top: 30px;margin-left: 10px;" >
                                <p style="margin:0px;font-size: 18px;">
                                    {{mortgageRequest.loan}}
                                </p>
                                <p style="margin:0px;font-size: 18px;">
                                    {{mortgageRequest.name}}
                                </p>
                                <p style="margin:0px;font-size: 18px;">
                                    <span v-if="mortgageRequest.mortgageWeight.v1 != null">{{mortgageRequest.mortgageWeight.v1}} ကျပ်</span>
                                        <span v-if="mortgageRequest.mortgageWeight.v2 != null">{{mortgageRequest.mortgageWeight.v2}} ပဲ</span>
                                        <span v-if="mortgageRequest.mortgageWeight.v3 != null">{{mortgageRequest.mortgageWeight.v3}} ရွေး</span> 
                                </p>
                            </div>

                            <div style="float:left;width:50px;height:100px;margin-top: 30px;font-weight: 900;" >
                                <p style="margin:0px;font-size: 24px;text-align:left;">
                                    <span v-if="mortgageRequest.noType != 'No'">{{mortgageRequest.noType}}-</span>{{mortgageRequest.pNoDate}}
                                </p>
                            </div>
                        </div>
                        <div class="row mt-2">
                             <button class="form-control" style="width: 10%;" @click="print()"> <i class="fas fa-print"></i> Print</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css'
    import Printd from 'printd'
    import moment from 'moment';
    export default {
        name: 'print',
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                mortgageRequest: {
					id: "",
                    shopId: "",
                    no: "",
					itemId: "",
					type: "",
					mortgageWeight: {v1: "", v2: "", v3: ""},
					loan: "",
					loanMmk: "",
					loanText: "",
					name: "",
					nrc: "",
					address: "",
					startDate: "",
                    day: "",
					month: "",
					year: "",
					endDate: "",
                    expDate: "",
					interestType: "percent",
					interestRate: 2,
					keep: '',
					loss: '',
					goldWeight: {v1: "", v2: "", v3: ""},
					jewelWeight: {v1: "", v2: "", v3: ""},
					weight: {v1: "", v2: "", v3: ""}
				},
            }
        },
        computed: {},
        methods: {
            ...mapActions({
				mortgageDetailAction: 'mortgageDetailAction',
			}),
            			async getDetail(){
				let option = {
					id:  this.$route.query.id
				}
				await this.mortgageDetailAction({
					...option
				}).then(res => {
					this.dataList = res.data.data
					this.mortgageRequest.id =  res.data.data.id
                    this.mortgageRequest.shopId =  res.data.data.shop_id
                    this.mortgageRequest.no =  res.data.data.no
                    this.mortgageRequest.noDate =  res.data.data.no_date
                    this.mortgageRequest.noType =  res.data.data.no_type
					this.mortgageRequest.itemId =  res.data.data.item_id
					this.mortgageRequest.type = res.data.data.type
					this.mortgageRequest.mortgageWeight.v1 = res.data.data.mortgage_weight.v1 
					this.mortgageRequest.mortgageWeight.v2 = res.data.data.mortgage_weight.v2
					this.mortgageRequest.mortgageWeight.v3 = res.data.data.mortgage_weight.v3
					this.mortgageRequest.loan = res.data.data.loan
					this.mortgageRequest.loanMmk = res.data.data.loan_mmk
					this.mortgageRequest.loanText = res.data.data.loan_text
					this.mortgageRequest.name = res.data.data.name 
					this.mortgageRequest.nrc = res.data.data.nrc
					this.mortgageRequest.address = res.data.data.address
					this.mortgageRequest.interestType = res.data.data.interest_type
					this.mortgageRequest.interestRate = res.data.data.interest_rate 
					this.mortgageRequest.keep = res.data.data.keep 
					this.mortgageRequest.loss = res.data.data.loss 
					this.mortgageRequest.startDate = res.data.data.start_date 
                    this.mortgageRequest.day = moment(res.data.data.start_date ).format('DD')
					this.mortgageRequest.month = moment(res.data.data.start_date ).format('MM')
					this.mortgageRequest.year = moment(res.data.data.start_date ).format('Y')
					this.mortgageRequest.endDate = moment(res.data.data.end_date ).format('DD-MM-y')
                    const initialDate = moment(res.data.data.start_date); // Replace with your specific initial date
                    var addDate = initialDate.add(4, 'months').add(11, 'days');
                    this.mortgageRequest.expDate = moment(addDate).format('DD-MM-y')
					this.mortgageRequest.goldWeight.v1 = res.data.data.gold.v1
					this.mortgageRequest.goldWeight.v2 = res.data.data.gold.v2
					this.mortgageRequest.goldWeight.v3 = res.data.data.gold.v3
					this.mortgageRequest.jewelWeight.v1 = res.data.data.jewel.v1
					this.mortgageRequest.jewelWeight.v2 = res.data.data.jewel.v2
					this.mortgageRequest.jewelWeight.v3 = res.data.data.jewel.v3
					this.mortgageRequest.weight.v1 = res.data.data.weight.v1
					this.mortgageRequest.weight.v2 = res.data.data.weight.v2
					this.mortgageRequest.weight.v3 = res.data.data.weight.v3
                    this.mortgageRequest.pNoDate = res.data.data.p_no_date
					let month = moment(res.data.data.start_date).format('M')
					if(this.mortgageRequest.filterType == 'type2'){
						this.mortgageRequest.referenceNote = month+"×"+res.data.data.no+"(လဲ)"
					}
					if(this.mortgageRequest.filterType == 'type3'){
						this.mortgageRequest.referenceNote = month+"×"+res.data.data.no+"(သွင်း)"
					}
					if(this.mortgageRequest.filterType == 'type4'){
						this.mortgageRequest.referenceNote = month+"×"+res.data.data.no+"(ယူ)"
					}
					this.isLoading = false
					}).catch(err => this.isLoading = true)
			},
            print() {
                const d = new Printd()
                d.print( document.getElementById('myelement') )
            },

        },
        async mounted() {
            this.getDetail()
        }
    }
</script>

<style scoped lang="scss">
    .action {
        border: none;
        background: none;
        font-size: 24px;
    }
</style>